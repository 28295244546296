import "./TenantUserList.scss";
import { getAxios } from "../requests/customAxios";
import { Icon, Table, Button, Segment, Header, Modal } from "semantic-ui-react"
import { navigate } from "@reach/router";
import { useTenant } from "../TenantsContext";
import { UsersContext } from "../TenantUsersContext";
import { useToasts } from "react-toast-notifications"
import React, { useState, useContext } from "react";

export default function TenantUserList(props) {
    const tenant = useTenant(props.tenantId);
    const { users, dispatchUsersChange } = useContext(UsersContext);
    const hasUsers = () => users && users.length > 0;

    function removeUser(userToRemove) {
        dispatchUsersChange({ type: "REMOVE_USER", value: userToRemove });
    }

    if (!tenant) return null;

    return (
        <div className="tenant-user-list">
            <h2>Benutzerübersicht für Kunde {tenant.name}</h2>
            {hasUsers() ?
                <UserListView users={users} tenant={tenant} onUserDelete={removeUser} />
                :
                <NoUsersView tenant={tenant} />
            }
        </div>
    );
}

function UserListView(props) {
    return (
        <React.Fragment>
            <CreateUserButton tenant={props.tenant} />
            <UserTable users={props.users} tenant={props.tenant} onUserDelete={props.onUserDelete} />
        </React.Fragment>
    );
}

function CreateUserButton(props) {
    function createNewUser() {
        navigate(`/tenants/${props.tenant.id}/users/create`);
    }

    return (
        <Button primary onClick={createNewUser}>
            <Icon name="add user" />
            Benutzer anlegen
        </Button>
    );
}

function NoUsersView(props) {
    return (
        <Segment placeholder>
            <Header icon>
                <Icon name="users" />
                Es sind keine Benutzer vorhanden.
                </Header>
            <CreateUserButton tenant={props.tenant} />
        </Segment>
    );
}

function UserTable(props) {
    function onEditUser(user) {
        navigate(`/tenants/${props.tenant.id}/users/${user.id}/edit`);
    }

    return (
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Vorname</Table.HeaderCell>
                    <Table.HeaderCell>Nachname</Table.HeaderCell>
                    <Table.HeaderCell>Benutzername</Table.HeaderCell>
                    <Table.HeaderCell>Aktionen</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    props.users.map((user, index) => {
                        return (
                            <Table.Row key={index}>
                                <Table.Cell>{user.first_name}</Table.Cell>
                                <Table.Cell>{user.last_name}</Table.Cell>
                                <Table.Cell>{user.username}</Table.Cell>
                                <Table.Cell className="action-column">
                                    <Icon name="edit" link size="large"
                                        onClick={() => onEditUser(user)} title="Benutzer bearbeiten" />
                                    <DeleteUserIcon user={user} onDelete={props.onUserDelete} />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })
                }
            </Table.Body>
        </Table>
    );
}

function DeleteUserIcon(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { addToast } = useToasts();

    async function deleteUser() {
        const axios = getAxios();

        try {
            await axios.delete(`/frontend_users/${props.user.id}`);
            setIsModalOpen(false);
            addToast("Benutzer erfolgreich gelöscht", { appearance: "success", autoDismiss: true })
            await props.onDelete(props.user);
        } catch (error) {
            setIsModalOpen(false);
            addToast(`Benutzer konnte nicht gelöscht werden: ${error.message}`, { appearance: "error", autoDismiss: true })
        }
    }

    const deleteIcon = <Icon name="trash" link size="large" color="red" title="Benutzer löschen" onClick={() => { setIsModalOpen(true) }} />
    return (
        <Modal trigger={deleteIcon} basic size="small" open={isModalOpen}>
            <Header icon="trash" content="Benutzer löschen" />
            <Modal.Content>
                <p>Wollen Sie den Benutzer wirklich löschen?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color="red" inverted onClick={() => setIsModalOpen(false)}>
                    <Icon name="remove" /> Nein
                </Button>
                <Button color="green" inverted onClick={deleteUser}>
                    <Icon name="checkmark" /> Ja
                </Button>
            </Modal.Actions>
        </Modal>
    );
}