import { useEffect } from "react";
import { navigate } from "@reach/router"
import { userInfoProvider } from "../../auth/UserInfoProvider";

export default function Login(props) {
    userInfoProvider.logout();

    useEffect(() => {
        const returnTo = async path => {
            await navigate(path);
        };

        if (!userInfoProvider.isLoggedIn) {
            returnTo("/login");
        }
    });

    return null;
}