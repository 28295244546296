const localStorageKey = "session";

class TokenProvider {

    get token() {
        const sessionInfoJson = localStorage.getItem(localStorageKey);
        if (sessionInfoJson) {
            const sessionInfo = JSON.parse(sessionInfoJson);

            if (!this.isExpired(sessionInfo)) {
                return sessionInfo.token;
            } else {
                this.removeToken();
            }
        }

        return null;
    }

    isExpired(sessionInfo) {
        const expirationDateTime = new Date(sessionInfo.expires);
        const currentDate = new Date();
        return expirationDateTime <= currentDate;
    }

    set token(token) {
        const expirationDateTime = new Date();
        expirationDateTime.setDate(expirationDateTime.getDate() + 14);
        const sessionInfo = {
            token: token,
            expires: expirationDateTime.getTime()
        };

        localStorage.setItem(localStorageKey, JSON.stringify(sessionInfo));
    }

    hasToken() {
        return !!this.token;
    }

    removeToken() {
        localStorage.removeItem(localStorageKey);
    }
}

export let tokenProvider = new TokenProvider();