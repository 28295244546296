import "./TenantList.scss";
import { getAxios } from "../requests/customAxios";
import { Icon, Table, Button, Segment, Header, Modal } from "semantic-ui-react"
import { navigate } from "@reach/router";
import { useToasts } from "react-toast-notifications"
import React, { useState, useContext } from "react";
import { TenantContext } from "../TenantsContext";

export default function TenantList(props) {
    const { tenants, dispatchTenantChange } = useContext(TenantContext);

    function hasTenants() {
        return tenants && tenants.length > 0;
    }

    function removeTenant(tenantToRemove) {
        dispatchTenantChange({ type: "REMOVE_TENANT", value: tenantToRemove });
    }

    return (
        <div className="tenant-list">
            <h2>Kundenübersicht</h2>
            {hasTenants() ?
                <TenantListView tenants={tenants} onTenantDelete={removeTenant} />
                :
                <NoTenantsView />
            }
        </div>
    );
}

function TenantListView(props) {
    return (
        <React.Fragment>
            <CreateTenantButton />
            <TenantTable tenants={props.tenants} onTenantDelete={props.onTenantDelete} />
        </React.Fragment>
    );
}

function CreateTenantButton(props) {
    function createNewTenant() {
        navigate(`/tenants/create`);
    }

    return (
        <Button primary onClick={createNewTenant}>
            <Icon name="add user" />
            Kunden anlegen
        </Button>
    );
}

function NoTenantsView(props) {
    return (
        <Segment placeholder>
            <Header icon>
                <Icon name="users" />
                Es sind keine Kunden vorhanden.
                </Header>
            <CreateTenantButton />
        </Segment>
    );
}

function TenantTable(props) {
    function onEditUsers(tenant) {
        navigate(`/tenants/${tenant.id}/users`, { state: { tenant: tenant } });
    }

    function onEditPolicies(tenant) {
        navigate(`/tenants/${tenant.id}/policies`, { state: { tenant: tenant } });
    }

    function onEditConditions(tenant) {
        navigate(`/conditions/${tenant.id}`);
    }

    return (
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Adresse</Table.HeaderCell>
                    <Table.HeaderCell>E-Mail</Table.HeaderCell>
                    <Table.HeaderCell>Aktionen</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    props.tenants.map((tenant) => {
                        return (
                            <Table.Row key={tenant.id}>
                                <Table.Cell>{tenant.name}</Table.Cell>
                                <Table.Cell>{tenant.address}</Table.Cell>
                                <Table.Cell>{tenant.email}</Table.Cell>
                                <Table.Cell className="action-column">
                                    <Icon name="users" link size="large"
                                        onClick={() => onEditUsers(tenant)} title="Benutzer verwalten" />
                                    <Icon name="cogs" link size="large"
                                        onClick={() => onEditPolicies(tenant)} title="Leasing-Regeln konfigurieren" />
                                    <Icon.Group size="large"
                                        onClick={() => onEditConditions(tenant)} title="Leasing-Bedingungen anpassen" >
                                        <Icon name="car" link />
                                        <Icon corner name="percent" link />
                                    </Icon.Group>
                                    <DeleteTenantIcon tenant={tenant} onDelete={props.onTenantDelete} />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })
                }
            </Table.Body>
        </Table>
    );
}

function DeleteTenantIcon(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { addToast } = useToasts();

    async function deleteTenant() {
        const axios = getAxios();

        try {
            await axios.delete(`/tenants/${props.tenant.id}`);
            setIsModalOpen(false);
            addToast("Kunde erfolgreich gelöscht", { appearance: "success", autoDismiss: true })

            props.onDelete(props.tenant);
        } catch (error) {
            setIsModalOpen(false);
            addToast("Kunde konnte nicht gelöscht werden", { appearance: "error", autoDismiss: true })
        }
    }

    const deleteIcon = <Icon name="trash" link size="large" color="red" title="Kunde löschen" onClick={() => { setIsModalOpen(true) }} />
    return (
        <Modal trigger={deleteIcon} basic size="small" open={isModalOpen}>
            <Header icon="trash" content="Kunde löschen" />
            <Modal.Content>
                <p>Wollen Sie den Kunden wirklich löschen?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color="red" inverted onClick={() => setIsModalOpen(false)}>
                    <Icon name="remove" /> Nein
                </Button>
                <Button color="green" inverted onClick={deleteTenant}>
                    <Icon name="checkmark" /> Ja
                </Button>
            </Modal.Actions>
        </Modal>
    );
}