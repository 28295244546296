import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import { Button, Form, Grid, Segment, Message, Icon, Header, Image } from "semantic-ui-react";
import { userInfoProvider } from "../../auth/UserInfoProvider";
import { getAxios } from "../requests/customAxios";

export default function Login(props) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [hasLoginError, setHasLoginError] = useState(false);

    async function login() {
        const axios = getAxios();
        setIsAuthenticating(true);
        setHasLoginError(false);

        try {
            const response = await axios.post("/login", {
                username: username,
                password: password
            });

            userInfoProvider.token = response.data.login.token;
            let userInfo = await getUserInfo();
            userInfoProvider.userInfo = userInfo;

            navigate("/");
        } catch {
            setHasLoginError(true);
        } finally {
            setIsAuthenticating(false);
        }
    }

    async function getUserInfo() {
        const axios = getAxios();
        const response = await axios.get("/users/me?include=tenant");
        return response.data;
    }

    function areCredentialsEmpty() {
        return !(username && password);
    }

    useEffect(() => {
        const returnTo = async path => {
            await navigate(path);
        };

        if (userInfoProvider.isLoggedIn) {
            returnTo("/");
        }
    });

    if (userInfoProvider.isLoggedIn) {
        return null;
    }

    return (
        <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
            <Grid.Column style={{ maxWidth: 450 }}>
                <Header textAlign="center">
                    <Image src="/logo.png" style={{ "fontSize": 100 }} />
                </Header>
                <Form size="large">
                    <Segment attached>
                        <Form.Input
                            fluid
                            icon="user"
                            iconPosition="left"
                            placeholder="Benutzername"
                            onChange={(event) => { setUserName(event.target.value); }} />
                        <Form.Input
                            fluid
                            icon="lock"
                            iconPosition="left"
                            placeholder="Passwort"
                            type="password"
                            onChange={(event) => { setPassword(event.target.value); }} />
                        <Button type="submit"
                            fluid
                            size="large"
                            primary
                            disabled={areCredentialsEmpty()}
                            loading={isAuthenticating}
                            onClick={login}>
                            Anmelden
                        </Button>
                    </Segment>
                </Form>
                {hasLoginError ? <Message attached="bottom" error><Icon name="exclamation triangle" />Benutzername oder Passwort ungültig!</Message> : null}
            </Grid.Column>
        </Grid>
    );
}