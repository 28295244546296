import { getAxios } from "./requests/customAxios";
import { useConditionalEffect } from "react-delta";
import { useToasts } from "react-toast-notifications"
import ContentLoadingIndicator from "./ContentLoadingIndicator";
import React, { useState, useReducer, useContext } from 'react';

export const TenantContext = React.createContext();

function tenantReducer(tenants, action) {
    switch (action.type) {
        case "REMOVE_TENANT":
            return tenants.filter((tenant) => tenant !== action.value);
        case "SET_TENANTS":
            return action.value;
        case "FORCE_RELOAD":
            // The API doesn't return an ID when a tenant is created,
            // so force a reload by returning null.
            return null;
        default:
            return tenants;
    }
}

export default function TenantsContext(props) {
    const [tenants, dispatchTenantChange] = useReducer(tenantReducer, null);
    const [isLoading, setIsLoading] = useState(false);
    const { addToast } = useToasts();

    useConditionalEffect(() => {
        async function loadTenants() {
            setIsLoading(true);
            const axios = getAxios();

            try {
                const request = await axios.get("/tenants");
                dispatchTenantChange({ type: "SET_TENANTS", value: request.data.tenants });
            }
            catch (error) {
                addToast(`Kunden konnten nicht geladen werden: ${error.message}`, { appearance: "error", autoDismiss: true })
            }
            finally {
                setIsLoading(false);
            }
        };

        loadTenants();
    }, !tenants && !isLoading);

    return (
        <TenantContext.Provider value={{ tenants: tenants, dispatchTenantChange: dispatchTenantChange }}>
            <ContentLoadingIndicator loading={isLoading} loadingText="Lade Kunden...">
                {props.children}
            </ContentLoadingIndicator>
        </TenantContext.Provider>
    )
}

export function useTenant(tenantId) {
    const { tenants } = useContext(TenantContext);
    const [tenant, setTenant] = useState(null);
    const hasTenants = () => tenants && tenants.length > 0;

    useConditionalEffect(() => {
        setTenant(tenants.find(tenant => tenant.id === tenantId));
    }, hasTenants() && !tenant);

    return tenant;
}