import { Button, Form, Input, Message, List } from "semantic-ui-react"
import { getAxios } from "../requests/customAxios";
import { navigate } from "@reach/router";
import { TenantContext } from "../TenantsContext";
import { useForm, Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications"
import React, { useState, useContext } from "react";

export default function CreateTenant(props) {
    const { dispatchTenantChange } = useContext(TenantContext);
    const { handleSubmit, errors, control } = useForm()
    const [isSaving, setIsSaving] = useState(false);
    const { addToast } = useToasts();

    async function saveNewTenant(tenant) {
        if (hasValidationErrors()) return;

        const axios = getAxios();
        setIsSaving(true);

        try {
            await axios.post("/tenants", {
                tenant: {
                    name: tenant.name,
                    address: tenant.address,
                    email: tenant.email
                }
            });

            addToast("Kunde erfolgreich angelegt", { appearance: "success", autoDismiss: true });
            dispatchTenantChange({ type: "FORCE_RELOAD" });
            navigateToTenantsList();
        } catch (error) {
            addToast(`Kunde konnte nicht angelegt werden: ${error.message}`, { appearance: "error", autoDismiss: true });
        } finally {
            setIsSaving(false);
        }
    }

    function cancel() {
        navigateToTenantsList();
    }

    function navigateToTenantsList() {
        navigate("/tenants");
    }

    function hasValidationErrors() {
        return Object.entries(errors).length > 0 && errors.constructor === Object;
    }

    return (
        <React.Fragment>
            <h2>Kunden anlegen</h2>
            <Form>
                <Form.Field required error={!!errors.name}>
                    <label>Kundenname</label>
                    <Controller as={Input}
                        name="name"
                        placeholder="Kundenname"
                        control={control}
                        defaultValue={""}
                        rules={{ required: "Bitte geben Sie einen Kundennamen ein!" }} />
                </Form.Field>
                <Form.Field required error={!!errors.address}>
                    <label>Adresse</label>
                    <Controller as={Input}
                        name="address"
                        placeholder="Adresse"
                        control={control}
                        defaultValue={""}
                        rules={{ required: "Bitte geben Sie eine Adresse ein!" }} />
                </Form.Field>
                <Form.Field required error={!!errors.email}>
                    <label>E-Mail</label>
                    <Controller as={Input}
                        name="email"
                        placeholder="E-Mail"
                        control={control}
                        defaultValue={""}
                        rules={{
                            required: "Bitte geben Sie eine E-Mail-Adresse ein!",
                            pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Bitte geben Sie eine <b>gültige</b> E-Mail-Adresse ein!"
                            }
                        }} />
                </Form.Field>
                <Message negative hidden={!hasValidationErrors()}>
                    <Message.Header>Fehler</Message.Header>

                    <List bulleted>
                        {Object.keys(errors).filter((formField) => !!errors[formField].message).map((formField, index) => <List.Item key={index}><span dangerouslySetInnerHTML={{ __html: errors[formField].message }}></span></List.Item>)}
                    </List>
                </Message>
                <Form.Group>
                    <Form.Field control={Button}
                        negative
                        onClick={cancel}>
                        Abbrechen
                    </Form.Field>
                    <Form.Field control={Button}
                        positive
                        disabled={hasValidationErrors()}
                        onClick={handleSubmit(saveNewTenant)}
                        loading={isSaving}>
                        Speichern
                    </Form.Field>
                </Form.Group>
            </Form>
        </React.Fragment>
    )
};