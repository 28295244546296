import {
    userInfoProvider
} from "../../auth/UserInfoProvider";
import axios from "axios";
import config from "../../config";

function getAxios() {
    const headers = {};

    if (userInfoProvider.isLoggedIn) {
        headers["Authorization"] = `Bearer ${userInfoProvider.token}`
    }

    const axiosInstance = axios.create({
        baseURL: config.baseUrl,
        headers: headers
    });

    return axiosInstance;
}

export {
    getAxios
};