import React from "react";
import { navigate } from "@reach/router";
import { Button, Icon } from "semantic-ui-react"
import { userInfoProvider } from "../auth/UserInfoProvider";
import "./NavigationHeaderBar.scss";

export default function (props) {
    function onHome() {
        navigate("/");
    }

    function onLogout() {
        navigate("/logout");
    }

    function homeButtonTitle() {
        return userInfoProvider.isAdmin ? "Kundenübersicht" : "Leasing-Bedingungen";
    }

    function getUsername() {
        return userInfoProvider.userInfo && userInfoProvider.userInfo.user.name;
    }

    return (
        <React.Fragment>
            {userInfoProvider.isLoggedIn ?
                <nav>
                    <React.Fragment>
                        <div className="left-content">
                            <Button primary icon="home" onClick={onHome} title={homeButtonTitle()} />
                        </div>
                        <div className="right-content">
                            <div className="username">
                                <Icon name="user" />
                                <span>{getUsername()}</span>
                            </div>
                            <Button negative icon="log out" onClick={onLogout} title="Abmelden" content="Abmelden"></Button>
                        </div>
                    </React.Fragment>
                </nav>
                :
                null
            }
        </React.Fragment >
    );
};