import React from "react";
import Login from "../pages/Login"
import NotFound from "../pages/NotFound";
import { userInfoProvider } from "../../auth/UserInfoProvider";

export default function PrivateRoute({ as: Component, ...props }) {
    if (!userInfoProvider.isLoggedIn) {
        return <Login />;
    }
    if (userInfoProvider.isLoggedIn && props.adminOnly && !userInfoProvider.isAdmin) {
        return <NotFound />
    } else {
        return <Component {...props} />
    }
}