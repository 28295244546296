import './App.scss';
import 'semantic-ui-css/semantic.min.css'
import { Router } from "@reach/router";
import { ToastProvider } from "react-toast-notifications"
import Conditions from './components/pages/Conditions';
import CreateTenant from "./components/pages/CreateTenant";
import deDE from "numbro/languages/de-DE";
import Home from './components/pages/Home';
import Login from "./components/pages/Login";
import Logout from "./components/pages/Logout";
import NavigationHeaderBar from "./components/NavigationHeaderBar";
import NotFound from "./components/pages/NotFound";
import numbro from "numbro";
import PrivateRoute from "./components/auth/PrivateRoute";
import React from 'react';
import TenantList from "./components/pages/TenantList";
import TenantPolicyConfigurator from "./components/pages/TenantPolicyConfigurator";
import TenantsContext from "./components/TenantsContext";
import TenantUsersContext from "./components/TenantUsersContext";
import TenantUser from './components/pages/TenantUser';
import TenantUserList from "./components/pages/TenantUserList";

numbro.registerLanguage(deDE);
numbro.setLanguage('de-DE');

function App() {
    return (
        <ToastProvider placement="bottom-right">
            <div className="App">
                <header>
                    <Router primary={false}>
                        <NavigationHeaderBar path="/*" />
                    </Router>
                </header>

                <section>
                    <Router>
                        <Root path="/">
                            <PrivateRoute path="/" as={Home} />
                            <PrivateRoute path="/tenants" as={TenantsContext}>
                                <PrivateRoute path="/" as={TenantList} adminOnly={true} />
                                <PrivateRoute path="create" as={CreateTenant} adminOnly={true} />
                                <PrivateRoute path="/:tenantId/users" as={TenantUsersContext}>
                                    <PrivateRoute path="/" as={TenantUserList} adminOnly={true} />
                                    <PrivateRoute path=":mode" as={TenantUser} adminOnly={true} />
                                    <PrivateRoute path=":userId/:mode" as={TenantUser} adminOnly={true} />
                                </PrivateRoute>
                                <PrivateRoute path=":tenantId/policies" as={TenantPolicyConfigurator} adminOnly={true} />
                            </PrivateRoute>
                            <PrivateRoute path="conditions/:tenantId" as={Conditions} />
                            <Login path="login" />
                            <Logout path="logout" />
                            <NotFound default />
                        </Root>
                    </Router>
                </section>
            </div>
        </ToastProvider>
    );
}

function Root(props) {
    return props.children;
}

export default App;