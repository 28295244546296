import React from 'react';
import { userInfoProvider } from "../../auth/UserInfoProvider";
import { Redirect } from "@reach/router";

export default function Home(props) {
    if (userInfoProvider.isAdmin) {
        return <Redirect to="/tenants" noThrow />
    } else {
        return <Redirect to="/conditions" noThrow />
    }
}