import "./TenantPolicyConfigurator.scss";
import { getAxios } from "../requests/customAxios";
import { Header, Input, Select, Form, Button } from "semantic-ui-react"
import { Maker, Model, BodyDesign, TrimLevel, Engine } from "../models/SelectableTreeModels";
import { useTenant } from "../TenantsContext";
import { useToasts } from "react-toast-notifications"
import CheckBoxGroup from "../CheckBoxGroup";
import numbro from "numbro";
import React, { useState, useEffect, useReducer } from "react";
import SelectableTree from "../SelectableTree";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class Policy {
    constructor(otherPolicy) {

        this.body_designs = [];
        this.capacity_max = "";
        this.co2 = "";
        this.doors = [];
        this.engines = [];
        this.fuel = [];
        this.makers = [];
        this.models = [];
        this.power = {
            from: "",
            to: "",
            unit: ""
        };
        this.rate1 = "";
        this.rate1_message = "";
        this.rate2 = "";
        this.rate2_message = "";
        this.rate3 = "";
        this.rate3_message = "";
        this.transmissions = [];
        this.trim_levels = [];
        this.gross_max = "";
        this.popup_message = "";

        if (otherPolicy) {
            // Merge server data with client defaults
            Object.assign(this, otherPolicy)
        }
    }

    toJSON() {
        return {
            capacity_max: numbro.unformat(this.capacity_max),
            co2: numbro.unformat(this.co2),
            rate1: numbro.unformat(this.rate1),
            rate2: numbro.unformat(this.rate2),
            rate3: numbro.unformat(this.rate3),
            rate1_message: this.rate1_message,
            rate2_message: this.rate2_message,
            rate3_message: this.rate3_message,
            makers: this.makers,
            models: this.models,
            body_designs: this.body_designs,
            trim_levels: this.trim_levels,
            engines: this.engines,
            doors: this.doors.map(numbro.unformat),
            transmissions: this.transmissions,
            fuel: this.fuel,
            power: {
                from: numbro.unformat(this.power.from),
                to: numbro.unformat(this.power.to),
                unit: this.power.unit
            },
            gross_max: numbro.unformat(this.gross_max),
            popup_message: this.popup_message
        };
    }
}

function policyReducer(state, action) {
    const newPolicyState = new Policy(state);
    switch (action.type) {
        case "SET_POLICY":
            return action.value;
        case "UPDATE_TRANSMISSIONS":
            newPolicyState.transmissions = action.value;
            return newPolicyState;
        case "UPDATE_DOORS":
            newPolicyState.doors = action.value;
            return newPolicyState;
        case "UPDATE_FUEL":
            newPolicyState.fuel = action.value;
            return newPolicyState;
        case "UPDATE_CAPACITY_MAX":
            newPolicyState.capacity_max = action.value;
            return newPolicyState;
        case "UPDATE_CO2":
            newPolicyState.co2 = action.value;
            return newPolicyState;
        case "UPDATE_POWER_FROM":
            newPolicyState.power.from = action.value;
            return newPolicyState;
        case "UPDATE_POWER_TO":
            newPolicyState.power.to = action.value;
            return newPolicyState;
        case "UPDATE_POWER_UNIT":
            newPolicyState.power.unit = action.value;
            return newPolicyState;
        case "UPDATE_RATE1":
            newPolicyState.rate1 = action.value;
            return newPolicyState;
        case "UPDATE_RATE2":
            newPolicyState.rate2 = action.value;
            return newPolicyState;
        case "UPDATE_RATE3":
            newPolicyState.rate3 = action.value;
            return newPolicyState;
        case "UPDATE_RATE1_MESSAGE":
            newPolicyState.rate1_message = action.value;
            return newPolicyState;
        case "UPDATE_RATE2_MESSAGE":
            newPolicyState.rate2_message = action.value;
            return newPolicyState;
        case "UPDATE_RATE3_MESSAGE":
            newPolicyState.rate3_message = action.value;
            return newPolicyState;
        case "UPDATE_GROSS_MAX":
            newPolicyState.gross_max = action.value;
            return newPolicyState;
        case "UPDATE_POPUP_MESSAGE":
            newPolicyState.popup_message = action.value;
            return newPolicyState;
        default:
            return state;
    }
}

export default function TenantPolicyConfigurator(props) {
    const tenant = useTenant(props.tenantId);
    const [makers, setMakers] = useState([]);
    const [catalog, setCatalog] = useState();
    const { addToast } = useToasts();
    const [policies, dispatchPolicyChange] = useReducer(policyReducer, new Policy());

    useEffect(() => {
        if (tenant) {
            dispatchPolicyChange({ type: "SET_POLICY", value: new Policy(tenant.policies) });
        }
    }, [tenant]);

    useEffect(() => {
        const axios = getAxios();
        async function fetchCatalog() {
            try {
                const response = await axios.get("/catalog");
                let catalog = await response.data;
                catalog.doors = catalog.doors
                    .map((doorCount) => parseInt(doorCount))
                    // TODO: Remove door count 0 in server catalog
                    .filter((doorCount) => doorCount > 0);
                catalog.powertypes = [
                    { key: "PS", value: "PS", text: "PS" },
                    { key: "kW", value: "kW", text: "kW" }
                ]
                setCatalog(catalog);
            }
            catch (error) {
                addToast(`Der Datenkatalog konnte nicht abgerufen werden: ${error.message}`, { appearance: "error", autoDismiss: true })
            }
        }

        async function fetchMakers() {
            try {
                const response = await axios.get("/makers");
                const selecionList = [...policies.makers, ...policies.models, ...policies.body_designs, ...policies.trim_levels, ...policies.engines];
                setMakers(response.data.makers.map(maker => new Maker(maker.id, maker.label, null, selecionList)));
            }
            catch (error) {
                addToast(`Hersteller konnten nicht abgerufen werden: ${error.message}`, { appearance: "error", autoDismiss: true })
            }
        }

        fetchCatalog();
        fetchMakers();
    }, [addToast, policies.makers, policies.models, policies.body_designs, policies.trim_levels, policies.engines]);

    async function savePolicies() {
        const allSelectedItems = makers.reduce((policy, maker) => {
            if (maker.isSelected) {
                policy.makers.push(maker.id);
            }

            for (const subItem of maker.getAllSelectedSubItems()) {
                if (subItem instanceof Model) {
                    policy.models.push(subItem.id);
                } else if (subItem instanceof BodyDesign) {
                    policy.body_designs.push(subItem.id);
                } else if (subItem instanceof TrimLevel) {
                    policy.trim_levels.push(subItem.id);
                } else if (subItem instanceof Engine) {
                    policy.engines.push(subItem.id);
                }
            }

            return policy;
        }, new Policy());

        policies.makers = allSelectedItems.makers;
        policies.models = allSelectedItems.models;
        policies.body_designs = allSelectedItems.body_designs;
        policies.trim_levels = allSelectedItems.trim_levels;
        policies.engines = allSelectedItems.engines;

        const axios = getAxios();
        try {
            await axios.put(`/tenants/${props.tenantId}`, {
                tenant: {
                    policies: policies
                }
            });
            addToast("Die Leasing-Regeln wurden erfolgreich gespeichert", { appearance: "success", autoDismiss: true })

        } catch (error) {
            addToast(`Die Leasing-Regeln konnten nicht gespeichert werden: ${error.message}`, { appearance: "error", autoDismiss: true })
        }
    }

    if (!tenant || !catalog || catalog.length === 0) return null;
    return (
        <div className="policies-page">
            <h2>Leasing-Regeln konfigurieren für {tenant.name}</h2>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field control={CheckBoxGroup}
                        label="Getriebe"
                        items={catalog.transmission}
                        columns={1}
                        value={policies.transmissions}
                        onChange={(newValue) => { dispatchPolicyChange({ type: "UPDATE_TRANSMISSIONS", value: newValue }) }} />
                    <Form.Field control={CheckBoxGroup}
                        label="Anzahl Türen"
                        items={catalog.doors}
                        columns={1}
                        value={policies.doors}
                        onChange={(newValue) => { dispatchPolicyChange({ type: "UPDATE_DOORS", value: newValue }) }} />
                    <Form.Field control={CheckBoxGroup}
                        label="Kraftstoff"
                        items={catalog.fuel}
                        columns={1}
                        value={policies.fuel}
                        onChange={(newValue) => { dispatchPolicyChange({ type: "UPDATE_FUEL", value: newValue }) }} />
                </Form.Group>
                <Header size="tiny">Motor</Header>
                <Form.Group widths="equal">
                    <Form.Field
                        control={Input}
                        label="ccm"
                        value={policies.capacity_max}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_CAPACITY_MAX", value: data.value }) }}
                    />
                    <Form.Field
                        control={Input}
                        label="CO²"
                        value={policies.co2}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_CO2", value: data.value }) }}
                    />
                </Form.Group>
                <Header size="tiny">Leistung</Header>
                <Form.Group widths="equal">
                    <Form.Field
                        control={Input}
                        label="von"
                        value={policies.power.from}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_POWER_FROM", value: data.value }) }}
                    />
                    <Form.Field
                        control={Input}
                        label="bis"
                        value={policies.power.to}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_POWER_TO", value: data.value }) }}
                    />
                    <Form.Field
                        control={Select}
                        label="Leistungsart"
                        options={catalog.powertypes}
                        value={policies.power.unit}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_POWER_UNIT", value: data.value }) }}
                    />
                </Form.Group>
                <Header size="tiny">Leasing</Header>
                <Form.Group widths="equal">
                    <Form.Field
                        control={Input}
                        label="Rate 1"
                        value={policies.rate1}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_RATE1", value: data.value }) }}
                    />
                    <Form.Field
                        control={Input}
                        label="Rate 2"
                        value={policies.rate2}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_RATE2", value: data.value }) }}
                    />
                    <Form.Field
                        control={Input}
                        label="Rate 3"
                        value={policies.rate3}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_RATE3", value: data.value }) }}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        control={Input}
                        label="Rate 1 Nachricht"
                        value={policies.rate1_message}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_RATE1_MESSAGE", value: data.value }) }}
                    />
                    <Form.Field
                        control={Input}
                        label="Rate 2 Nachricht"
                        value={policies.rate2_message}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_RATE2_MESSAGE", value: data.value }) }}
                    />
                    <Form.Field
                        control={Input}
                        label="Rate 3 Nachricht"
                        value={policies.rate3_message}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_RATE3_MESSAGE", value: data.value }) }}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        control={Input}
                        label="maximaler Bruttolistenpreis"
                        value={policies.gross_max}
                        onChange={(event, data) => { dispatchPolicyChange({ type: "UPDATE_GROSS_MAX", value: data.value }) }}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <div className="field">
                        <label>Popup Nachricht</label>
                        <div className="ui input">
                            <ReactQuill theme="snow" value={policies.popup_message} onChange={(doc, data) => { dispatchPolicyChange({type: "UPDATE_POPUP_MESSAGE", value: doc}) }} />
                        </div>
                    </div>
                </Form.Group>
            </Form>
            <SelectableTree items={makers} size="big" celled viewtype="policy" />
            <Button primary className="save-button" onClick={savePolicies}>Speichern</Button>
        </div>
    );
}