import {
    getAxios
} from "../requests/customAxios";
import numbro from "numbro";

class Condition {
    constructor(serverCondition, entityType, entityId) {
        this.setServerValues(serverCondition);
        this.entity = (serverCondition && serverCondition.entity) || entityType;
        this.entity_id = (serverCondition && serverCondition.entity_id) || entityId;
    }

    setServerValues(serverCondition) {
        this.id = (serverCondition && serverCondition.id) || null;
        this.entity = (serverCondition && serverCondition.entity) || null;
        this.entity_id = (serverCondition && serverCondition.entity_id) || null;
        this.attribute_name = (serverCondition && serverCondition.attribute_name) || null;
        this.attribute_value = (serverCondition && serverCondition.attribute_value) || null;
        this._discount = (serverCondition && serverCondition.discount) || null;
        this._reduction = (serverCondition && serverCondition.reduction) || null;
        this._provision_costs = (serverCondition && serverCondition.provision_costs) || null;
        this._leasing_factor = (serverCondition && serverCondition.leasing_factor) || null;
        this._isDirty = false;
    }

    get discount() {
        if (this._discount) {
            return numbro(this._discount).format();
        }
        return this._discount;
    }

    set discount(newDiscount) {
        if (newDiscount) {
            this._discount = numbro.unformat(newDiscount);
            this._isDirty = true;
        } else {
            this._discount = null;
        }
    }

    get reduction() {
        if (this._reduction) {
            return numbro(this._reduction).format();
        }
        return this._reduction;
    }

    set reduction(newReduction) {
        if (newReduction) {
            this._reduction = numbro.unformat(newReduction);
            this._isDirty = true;
        } else {
            this._reduction = null;
        }
    }

    get provision_costs() {
        if (this._provision_costs) {
            return numbro(this._provision_costs).format();
        }
        return this._provision_costs;
    }

    set provision_costs(newProvisionCosts) {
        if (newProvisionCosts) {
            this._provision_costs = numbro.unformat(newProvisionCosts);
            this._isDirty = true;
        } else {
            this._provision_costs = null;
        }
    }

    get leasing_factor() {
        if (this._leasing_factor) {
            return numbro(this._leasing_factor).format();
        }
        return this._leasing_factor;
    }

    set leasing_factor(newLeasingFactor) {
        if (newLeasingFactor) {
            this._leasing_factor = numbro.unformat(newLeasingFactor);
            this._isDirty = true;
        } else {
            this._leasing_factor = null;
        }
    }

    get isNew() {
        return !this.id;
    }

    get isDirty() {
        return !this.isNew && this._isDirty;
    }

    get isEmpty() {
        return !(this.discount || this.reduction || this.provision_costs || this.leasing_factor);
    }

    toJSON() {
        return {
            id: this.id,
            entity: this.entity,
            entity_id: this.entity_id,
            attribute_name: this.attribute_name,
            attribute_value: this.attribute_value,
            discount: this._discount,
            reduction: this._reduction,
            provision_costs: this._provision_costs,
            leasing_factor: this._leasing_factor,
            subtenant_id: this.subtenant_id
        }
    }
}

class TreeNode {
    constructor(id, label, conditionMap, selectionList, isInclude=true) {
        this.id = id;
        this.label = label;
        this.subItems = [];

        if(isInclude) {
            this.isSelected = selectionList && selectionList.includes(this.id);
        } else {
            this.isSelected = selectionList && !selectionList.includes(this.id);
        }

        /*  if(!isInclude) {
            this.isSelected = !this.isSelected;
        } */

        this._conditionMap = conditionMap || {};
        this._selectionList = selectionList || [];
        this.condition = new Condition(this._conditionMap[this.id], this.type, this.id);
        this.isInclude = isInclude;
        
    }

    get leasing_factor() {
        return this.condition.leasing_factor || "";
    }

    set leasing_factor(newLeasingFactor) {
        this.condition.leasing_factor = newLeasingFactor;
    }

    get type() {
        throw new Error("Not implemented!");
    }

    async getSubItems() {
        if (this.subItems && this.subItems.length === 0) {
            this.subItems = await this.fetchSubItems();
        }
        return this.subItems;
    }

    async fetchSubItems() {
        throw new Error("Not implemented!");
    }

    getAllConditions() {
        let allSubConditions = this.subItems.reduce((accumulator, currentValue) => {
            let subConditions = currentValue.getAllConditions();
            return [...accumulator, currentValue.condition, ...subConditions];
        }, []);

        return allSubConditions;
    }

    getAllSelectedSubItems() {
        if(this.isInclude && !this.isSelected) {
            return [];
        }
        
        let allSelectedSubItems = this.subItems.reduce((accumulator, currentValue) => {
            let subSelectedItems = currentValue.getAllSelectedSubItems();
            let isSelected = currentValue.isSelected;

            if(currentValue.isInclude && currentValue.isSelected) {

                accumulator.push(currentValue);
            } else {
                if(!currentValue.isInclude && !currentValue.isSelected) {
                    accumulator.push(currentValue);
                }
            }
            
            return [...accumulator, ...subSelectedItems];
        }, []);

        return allSelectedSubItems;
    }
}

class Maker extends TreeNode {

    get type() {
        return "maker";
    }

    async fetchSubItems() {
        const axios = getAxios();
        const response = await axios.get(`makers/${this.id}/models`);
        return response.data.models.map((model) => new Model(model.id, model.label, this._conditionMap, this._selectionList, false));
    }
}

class Model extends TreeNode {
    get type() {
        return "model";
    }

    async fetchSubItems() {
        const axios = getAxios();
        const response = await axios.get(`/models/${this.id}/body_designs`);
        return response.data.body_designs.map((bodyDesign) => new BodyDesign(bodyDesign.id, bodyDesign.label, this._conditionMap, this._selectionList, false));
    }
}

class BodyDesign extends TreeNode {
    get type() {
        return "body_design";
    }

    async fetchSubItems() {
        const axios = getAxios();
        const response = await axios.get(`/body_designs/${this.id}/trim_levels`);
        return response.data.trim_levels.map((trimLevel) => new TrimLevel(trimLevel.id, trimLevel.label, this._conditionMap, this._selectionList, false));
    }
}

class TrimLevel extends TreeNode {
    get type() {
        return "trim_level";
    }

    async fetchSubItems() {
        const axios = getAxios();
        const response = await axios.get(`/trim_levels/${this.id}/engines`);
        return response.data.engines.map((engine) => new Engine(engine, this._conditionMap, this._selectionList, false));
    }
}

class Engine extends TreeNode {
    get type() {
        return "engine";
    }

    constructor(serverEngine, conditionMap, selectionList, isInclude=true) {
        super(serverEngine.id, serverEngine.label, conditionMap, selectionList, isInclude);
        this.transmission = serverEngine.transmission;
        this.drive = serverEngine.drive;
        this.fuelType = serverEngine.fuel_type;
        this.jatoUid = serverEngine.jato_uid;
        this.minPriceGross = serverEngine.min_price_gross;        
    }

    async fetchSubItems() {
        return [];
    }
}

export {
    Maker,
    Model,
    BodyDesign,
    TrimLevel,
    Engine
};