import { Segment, Loader } from "semantic-ui-react"
import React from "react";

export default function ContentLoadingIndicator(props) {
    if (props.loading) {
        return (
            <Segment basic style={{ height: "300px" }}>
                <Loader active={props.loading} size="large">
                    {props.loadingText}
                </Loader>
            </Segment>
        );
    }

    return props.children;
}