import React, { useState, useEffect } from "react";
import { List, Checkbox, Segment, Dimmer, Loader, Input, Popup } from "semantic-ui-react"
import { Engine, Maker } from "./models/SelectableTreeModels"
import "./SelectableTree.scss"

export default function SelectableTree(props) {
    const { items, ...listProps } = props;
    return (
        <React.Fragment>
            <List className="selectable-tree" {...listProps}>
                {
                    items.map((item, index) => {
                        return (
                            <List.Item key={index}>
                                <SelectableTreeItem item={item} viewtype={props.viewtype} leasingfactorvalue={props.leasingfactorvalue} />
                            </List.Item>
                        );
                    })
                }
            </List>
        </React.Fragment>
    );
}

function SelectableTreeItem(props) {
    const [isToggled, setIsToggled] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [subItems, setSubItems] = useState([]);
    const [leasingFactorValue, setLeasingFactorValue] = useState(props.item.leasing_factor);

    function getIcon() {
        return isToggled ? "caret down" : "caret right";
    }

    function getSubItemsVisible() {
        if(props.item.isInclude && !props.item.isSelected) {
            return false;
        }

        return subItems && subItems.length > 0;
    }    

    useEffect(() => {
        setIsChecked(props.item.isSelected);
    }, [props.item.isSelected]);

    useEffect(() => {
        const getSubItems = async () => {
            let subItems = await props.item.getSubItems();
            // TODO: loading indicator does not stay visible during loading. why?
            setIsLoading(false);
            setSubItems(subItems);
        };
        getSubItems();
    }, [isToggled, props.item]);

    useEffect(() => {
        props.item.leasing_factor = leasingFactorValue;
    }, [leasingFactorValue, props.item.leasing_factor]);

    function onIconClicked(event, data) {
        setIsLoading(true);
        setIsToggled(!isToggled);
    }

    function onCheckBoxClicked(event, data) {
        setIsChecked(data.checked);
        props.item.isSelected = data.checked;
    }

    function isEngine() {
        return props.item instanceof Engine;
    }

    function isMaker() {
        return props.item instanceof Maker;
    }

    if (props.viewtype === "policy") {
        return (
            <React.Fragment>
                <ItemCheckbox icon={getIcon()} onClick={onIconClicked} visible={getSubItemsVisible()} />
                <List.Content>
                    {isEngine() ?
                        <EngineListContent {...props} checked={isChecked} onClick={onCheckBoxClicked} viewtype={props.viewtype} /> :
                        <List.Header>
                            <Checkbox label={props.item.label} value={props.item.id} checked={isChecked} onClick={onCheckBoxClicked} />
                        </List.Header>
                    }
                    <LoadingIndicator active={isLoading}>
                        {isToggled ? <SelectableTree items={subItems} viewtype={props.viewtype}  /> : null}
                    </LoadingIndicator>
                </List.Content>
            </React.Fragment>
        );
    } else if (props.viewtype === "conditions") {
        return (
            <React.Fragment>
                <ItemCheckbox icon={getIcon()} onClick={onIconClicked} visible={subItems && subItems.length > 0} />
                <List.Content>
                    {isEngine() ?
                        <EngineListContent {...props} checked={isChecked} onClick={onCheckBoxClicked} viewtype={props.viewtype} /> :
                        isMaker() ?
                            <List.Header>
                                {props.item.label}
                            </List.Header> :

                            <React.Fragment>
                                <List.Header>
                                    {props.item.label}
                                </List.Header>
                                <List.Description>
                                    <LeasingFactorInput placeholder={props.leasingfactorvalue} value={leasingFactorValue} onChange={(event, data) => setLeasingFactorValue(data.value)} />
                                </List.Description>
                            </React.Fragment>
                    }
                    <LoadingIndicator active={isLoading}>
                        {isToggled ? <SelectableTree items={subItems} viewtype={props.viewtype} leasingfactorvalue={leasingFactorValue || props.leasingfactorvalue} /> : null}
                    </LoadingIndicator>
                </List.Content>
            </React.Fragment>
        );
    }

    return null;
}

function EngineListContent(props) {
    const [leasingFactorValue, setLeasingFactorValue] = useState(props.item.leasingFactor);

    useEffect(() => {
        props.item.leasingFactor = leasingFactorValue;
    }, [leasingFactorValue, props.item.leasingFactor]);

    const engineDetails = `(${props.item.fuelType}, ${props.item.transmission}, ${props.item.drive}, ${props.item.jatoUid}, ${new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR", minimumFractionDigits: 0 }).format(props.item.minPriceGross)})`;
    return (
        <React.Fragment>
            <List.Header>
                {props.viewtype === "policy" ?
                    <Checkbox label={props.item.label} value={props.item.id} checked={props.checked} onClick={props.onClick} /> :
                    props.item.label
                }
            </List.Header>
            <List.Description className="engine-details">{engineDetails}</List.Description>
            {props.viewtype === "conditions" ? <LeasingFactorInput placeholder={props.leasingfactorvalue} value={leasingFactorValue} onChange={(event, data) => setLeasingFactorValue(data.value)} /> : null}
        </React.Fragment>
    );
}

function ItemCheckbox(props) {
    if (props.visible) return <List.Icon name={props.icon} link onClick={props.onClick} size="large" />;
    else return null;
}

function LeasingFactorInput(props) {
    const leasingFactorInput = <Input icon="percent"
        iconPosition="left"
        size="mini"
        label={{ tag: true, content: "Faktor" }}
        labelPosition="right"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange} />;

    return <Popup
        trigger={leasingFactorInput}
        mouseEnterDelay={500}
        header={"Wichtig"}
        content="Werte werden nach unten vererbt!"
        size="small" />
}

function LoadingIndicator(props) {
    return (
        <Segment basic>
            <Dimmer active={props.active} inverted>
                <Loader size="small">Lade Daten...</Loader>
            </Dimmer>
            {props.children}
        </Segment>
    );
}