import {
    tokenProvider
} from "./TokenProvider";

const localStorageKey = "userInfo";

class UserInfoProvider {
    get token() {
        return tokenProvider.token;
    }

    set token(token) {
        tokenProvider.token = token;
    }

    get userInfo() {
        let userInfo = localStorage.getItem(localStorageKey);
        if (userInfo) return JSON.parse(userInfo);
        else return null;
    }

    set userInfo(userInfo) {
        localStorage.setItem(localStorageKey, JSON.stringify(userInfo));
    }

    get isLoggedIn() {
        return tokenProvider.hasToken();
    }

    get isAdmin() {
        if (this.userInfo && !this.userInfo.user.tenant.parent_tenant) return true;
        else return false;
    }

    logout() {
        localStorage.removeItem(localStorageKey);
        tokenProvider.removeToken();
    }
}

export let userInfoProvider = new UserInfoProvider();