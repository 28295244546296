import { getAxios } from "./requests/customAxios";
import { useConditionalEffect } from "react-delta";
import { useToasts } from "react-toast-notifications"
import ContentLoadingIndicator from "./ContentLoadingIndicator";
import React, { useState, useReducer, useContext } from 'react';
import { TenantContext } from "./TenantsContext";

export const UsersContext = React.createContext();

function usersReducer(users, action) {
    switch (action.type) {
        case "SET_USER":
            let otherUsers = (users || []).filter((user) => user !== action.value);
            otherUsers.push(action.value);
            return otherUsers;
        case "REMOVE_USER":
            return users.filter((user) => user !== action.value);
        case "SET_USERS":
            return action.value;
        case "FORCE_RELOAD":
            // The API doesn't return an ID when a user is created,
            // so force a reload by returning null.
            return null;
        default:
            return users;
    }
}

export default function TenantUsersContext(props) {
    const { tenants } = useContext(TenantContext);
    const [tenant, setTenant] = useState(null);
    const hasTenants = () => tenants && tenants.length > 0;

    const [users, dispatchUsersChange] = useReducer(usersReducer, null);
    const [isLoading, setIsLoading] = useState(false);
    const { addToast } = useToasts();

    useConditionalEffect(() => {
        setTenant(tenants.find(tenant => tenant.id === props.tenantId));
    }, hasTenants() && !tenant);

    useConditionalEffect(() => {
        async function loadUsers() {
            setIsLoading(true);
            const axios = getAxios();
            try {
                const response = await axios.get(`/frontend_users/${tenant.id}`);
                dispatchUsersChange({ type: "SET_USERS", value: response.data.frontend_users });
            } catch (error) {
                addToast(`Benutzer konnten nicht geladen werden: ${error.message}`, { appearance: "error", autoDismiss: true })
            } finally {
                setIsLoading(false);
            }
        };

        loadUsers();
    }, tenant && !users && !isLoading);

    return (
        <UsersContext.Provider value={{ users: users, dispatchUsersChange: dispatchUsersChange }}>
            <ContentLoadingIndicator loading={isLoading} loadingText="Lade Benutzer...">
                {props.children}
            </ContentLoadingIndicator>
        </UsersContext.Provider>
    )
}

export function useUser(userId) {
    const { users, dispatchUsersChange } = useContext(UsersContext);
    const [user, setUserState] = useState();
    const hasUsers = () => users && users.length > 0;

    useConditionalEffect(() => {
        setUserState(users.find(user => user.id === userId));
    }, hasUsers() && !user);

    function setUser(newUser) {
        let updatedUser = Object.assign(user || {}, newUser || {});
        setUserState(updatedUser);
        dispatchUsersChange({ type: "SET_USER", value: updatedUser });
    }

    return [user, setUser];
}