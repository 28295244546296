import React, { useState } from "react";
import { Checkbox, Header, Grid } from "semantic-ui-react"

export default function CheckBoxGroup(props) {
    const validSelectedValues = props.items.filter((item) => { return (props.value || []).includes(item) });
    const [selectedValues, setSelectedValue] = useState(validSelectedValues);

    function onValueChange(event, data) {
        let updatedSelectedValues = null;
        if (data.checked) {
            updatedSelectedValues = [...selectedValues, data.value];
        } else {
            updatedSelectedValues = selectedValues.filter(item => { return item !== data.value });
        }

        setSelectedValue(updatedSelectedValues);
        props.onChange(updatedSelectedValues);
    }

    return (
        <Grid>
            <Header>{props.label}</Header>
            <Grid.Row columns={props.columns}>
                {
                    props.items.map((label, index) => {
                        return (
                            <Grid.Column key={index}>
                                <Checkbox label={label} value={label} checked={selectedValues.includes(label)} onChange={onValueChange} />
                            </Grid.Column>
                        )
                    })
                }
            </Grid.Row>
        </Grid>
    );
}