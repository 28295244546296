import React from "react";
import { Link } from "@reach/router";
import { Grid, Icon, Header } from "semantic-ui-react";

export default function NotFound(props) {
    return (
        <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
            <Grid.Column style={{ maxWidth: 450 }}>
                <Header size="huge" icon >
                    <Icon name="close" color="red" />
                    Seite nicht gefunden
                    <Header.Subheader>
                        <div>
                            Die von Ihnen aufgerufene Seite wurde nicht gefunden.
                            </div>
                        <div>
                            <Link to="/">Zurück zur Startseite</Link>
                        </div>
                    </Header.Subheader>
                </Header>
            </Grid.Column>
        </Grid>
    );
}