import { Button, Form, Input, Message, List} from "semantic-ui-react"
import { getAxios } from "../requests/customAxios";
import { navigate } from "@reach/router";
import { useForm, Controller } from "react-hook-form";
import { UsersContext, useUser } from "../TenantUsersContext";
import { useToasts } from "react-toast-notifications"
import React, { useState, useContext, useEffect } from "react";

export default function TenantUser(props) {
    const [contextUser] = useUser(props.userId);
    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        username: "",
        password: ""
    });
    const { dispatchUsersChange } = useContext(UsersContext);
    const [isSaving, setIsSaving] = useState(false);
    const { addToast } = useToasts();
    const { handleSubmit, errors, control, setValue } = useForm()

    useEffect(() => {
        if (contextUser) {
            setUser(contextUser);
            setValue("first_name", contextUser.first_name || "");
            setValue("last_name", contextUser.last_name || "");
            setValue("username", contextUser.username || "");
            setValue("password", contextUser.password || "");
        }
    }, [contextUser, setValue]);

    async function saveUser(user) {
        if (hasValidationErrors()) return;
        setIsSaving(true);

        const axios = getAxios();
        try {
            if (props.mode === "create") {
                await axios.post("/frontend_users", {
                    frontend_user: {
                        tenant_id: props.tenantId,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        username: user.username,
                        password: user.password
                    }
                });
            }
            else {
                let userToSave = {
                    tenant_id: props.tenantId,
                    first_name: user.first_name,
                    last_name: user.last_name
                };
                if (user.password) {
                    userToSave.password = user.password;
                }

                await axios.put(`/frontend_users/${props.userId}`, {
                    frontend_user: userToSave
                });
            }

            addToast("Benutzer erfolgreich gespeichert", { appearance: "success", autoDismiss: true });
            dispatchUsersChange({ type: "FORCE_RELOAD" });
            navigateToTenantUsersList();
        } catch (error) {
            addToast(`Fehler beim Speichern des Benutzers: ${error.message}`, { appearance: "error", autoDismiss: true });
        } finally {
            setIsSaving(false);
        }
    }

    function cancel() {
        navigateToTenantUsersList();
    }

    function navigateToTenantUsersList() {
        navigate(`/tenants/${props.tenantId}/users`);
    }

    function hasValidationErrors() {
        return Object.entries(errors).length > 0 && errors.constructor === Object;
    }

    if (!user) return null;
    return (
        <React.Fragment>
            {props.mode === "create" ?
                <h2>Benutzer anlegen</h2> :
                <h2>Benutzer bearbeiten</h2>
            }
            <Form>
                <Form.Field required error={!!errors.first_name}>
                    <label>Vorname</label>
                    <Controller as={Input}
                        name="first_name"
                        placeholder="Vorname"
                        control={control}
                        defaultValue={""}
                        rules={{ required: "Bitte geben Sie einen Vornamen ein!" }} />
                </Form.Field>
                <Form.Field required error={!!errors.last_name}>
                    <label>Nachname</label>
                    <Controller as={Input}
                        name="last_name"
                        placeholder="Nachname"
                        control={control}
                        defaultValue={""}
                        rules={{ required: "Bitte geben Sie einen Nachnamen ein!" }} />
                </Form.Field>
                {props.mode === "create" ?
                    <Form.Field required error={!!errors.username}>
                        <label>Benutzername</label>
                        <Controller as={Input}
                            name="username"
                            placeholder="Benutzername"
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Bitte geben Sie einen Benutzernamen ein!" }} />
                    </Form.Field> :
                    null
                }
                <Form.Field required={props.mode === "create"} error={!!errors.password}>
                    <label>Passwort</label>
                    <Controller as={Input}
                        name="password"
                        placeholder="Passwort"
                        type="password"
                        control={control}
                        defaultValue={""}
                        rules={{ required: (props.mode === "create" ? "Bitte geben Sie ein Password ein!" : false) }} />
                </Form.Field>
                {props.mode === "create" ? null : <div><Message><Message.Header>Auth Token</Message.Header> {user.token}</Message><br/></div>}
                <Message negative hidden={!hasValidationErrors()}>
                    <Message.Header>Fehler</Message.Header>
                    <List bulleted>
                        {Object.keys(errors).filter((formField) => !!errors[formField].message).map((formField, index) => <List.Item key={index}>{errors[formField].message}</List.Item>)}
                    </List>
                </Message>
                <Form.Group>
                    <Form.Field control={Button}
                        negative
                        onClick={cancel}>
                        Abbrechen
                    </Form.Field>
                    <Form.Field control={Button}
                        positive
                        disabled={hasValidationErrors()}
                        onClick={handleSubmit(saveUser)}
                        loading={isSaving}>
                        Speichern
                </Form.Field>
                </Form.Group>
            </Form>
        </React.Fragment>
    )
};