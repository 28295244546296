import "./Conditions.scss";
import { Button } from "semantic-ui-react"
import { getAxios } from "../requests/customAxios";
import { Maker } from "../models/SelectableTreeModels";
import { useToasts } from "react-toast-notifications"
import React, { useState, useEffect } from "react";
import SelectableTree from "../SelectableTree";

export default function Conditions(props) {
    const [makers, setMakers] = useState([]);
    const { addToast } = useToasts();

    async function saveNewCondition(condition) {
        const axios = getAxios();
        condition.subtenant_id = props.tenantId;
        const responsePromise = axios.post("/conditions", { condition: condition });
        responsePromise.then((response) => {
            condition.setServerValues(response.data.condition);
        });

        return responsePromise;
    }

    async function saveUpdatedCondition(condition) {
        const axios = getAxios();
        condition.subtenant_id = props.tenantId;
        return axios.put(`conditions/${condition.id}`, { condition: condition });
    }

    async function deletedCondition(condition) {
        const axios = getAxios();
        condition.subtenant_id = props.tenantId;
        axios({
            method: 'delete',
            url: `conditions/${condition.id}`,
            data: {
                condition: condition
            }
        });
    }

    async function saveConditions() {
        const allConditions = makers.reduce((allConditions, maker) => {
            return [...allConditions, ...maker.getAllConditions()];
        }, []);

        const updatedConditions = allConditions.filter((condition) => !condition.isNew && condition.isDirty && !condition.isEmpty);
        const newConditions = allConditions.filter((condition) => condition.isNew && !condition.isEmpty);
        const deletedConditions = allConditions.filter((condition) => condition.isEmpty && !condition.isNew);

        const savePromises = [];
        for (const condition of updatedConditions) {
            savePromises.push(saveUpdatedCondition(condition));
        }

        for (const condition of newConditions) {
            savePromises.push(saveNewCondition(condition));
        }

        for (const condition of deletedConditions) {
            savePromises.push(deletedCondition(condition));
        }

        try {
            await Promise.all(savePromises);
            addToast("Änderungen gespeichert", { appearance: "success", autoDismiss: true })
        } catch (error) {
            addToast(`Änderungen konnten nicht gespeichert werden: ${error.message}`, { appearance: "error", autoDismiss: true })
        }
    }

    useEffect(() => {
        const axios = getAxios();
        async function fetchConditions() {
            try {
                const response = await axios.get(`/conditions/for_subtenant/${props.tenantId}`);
                let conditionMap = response.data.conditions.reduce((conditionMap, currentCondition) => {
                    conditionMap[currentCondition.entity_id] = currentCondition;
                    return conditionMap;
                }, {});
                return conditionMap;
            } catch (error) {
                addToast(`Bedingungen konnten nicht abgerufen werden: ${error.message}`, { appearance: "error", autoDismiss: true })
            }
        }

        async function fetchMakers(conditionMap) {
            try {
                const response = await axios.get("/makers");
                return response.data.makers.map(maker => new Maker(maker.id, maker.label, conditionMap));
            } catch (error) {
                addToast(`Hersteller konnten nicht abgerufen werden: ${error.message}`, { appearance: "error", autoDismiss: true })
            }
        }

        async function getData() {
            let conditionMap = await fetchConditions();
            let makers = await fetchMakers(conditionMap);
            setMakers(makers);
        }

        getData();
    }, [addToast, props.tenantId]);

    return (
        <div className="conditions-page">
            <h2>Leasing-Bedingungen bearbeiten</h2>
            <SelectableTree items={makers} size="big" celled viewtype="conditions" />
            <Button primary className="save-button" onClick={saveConditions}>Speichern</Button>
        </div>
    );
};